// rtl
$rtl: ".rtl :host &";
// colors
:root {
  --primary-color: #00b7bd;
}

$primary: #00b7bd;
$primary-middle: #5cadf4;
$primary-light: #d8f3eb;
$secondary: #1591e4;
$secondary-middle: #b49de7;
$secondary-light: #ccecff;
$third: #f6d105;
$third-middle: #ff9d83;
$third-light: #fff9e5;
$green: #42a850;
$green-middle: #7cd584;
$green-light: #d8f3eb;
$red: #ff424e;
$red-middle: #f75d5a;
$red-light: #fccfd3;
$orange: #ff8300;
$orange-light: #ffd9cc;
$yellow: #fffa3b;
$yellow-light: #fffbb3;
$grey: #8a94a6;
$grey-light: #e2e4e9;
$black: #292d34;
$dark-mode-cards: #434a56;
$disabled: #c1c2c2;
$white: #f2f2f2;
$real-white: #ffffff;
$background-color: #f7f7f7;

// breakpoint media query
$desktop-view: "screen and (min-width: 960px)";
$mobile: "screen and (max-width: 959px)";
$smallMobile: "screen and (max-width: 599px)";

$lg: "screen and (min-width: 1280px )"; // = gt-lg // lg = min: 1280, max: 1919.98
$md: "screen and (min-width: 959.98px) and (max-width:1279.98px)"; // = lt.md // md = min: 960, max: 1279.98
$sm: "screen and (min-width: 599.98px) and (max-width:959.98px)"; // = lt-sm // sm = min: 600, max: 959.98
$xs: "screen and (max-width: 599.98px)";

$box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
$box-shadow-light: 0 2px 6px 0 rgb(0 0 0 / 4%);

// @font-face {
//   font-family: Poppins-semi-bold;
//   src: url("assets/fonts/Poppins/Poppins-SemiBold.ttf");
//   font-weight: 600;
// }

@font-face {
  font-family: Poppins-Regular;
  src: url("assets/fonts/Poppins/Poppins-Regular.ttf");
}

// @font-face {
//   font-family: Poppins-Medium;
//   src: url("assets/fonts/Poppins/Poppins-Medium.ttf");
//   font-weight: 500;
// }

@font-face {
  font-family: Poppins-italic;
  src: url("assets/fonts/Poppins/Poppins-Italic.ttf");
  font-weight: 400;
}

@mixin heading {
  font-family: Poppins-Regular;
  font-size: 24px;
  line-height: 1.2;
  font-weight: 600;
}
@mixin subheading {
  font-family: Poppins-Regular;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
}

@mixin medium {
  font-family: Poppins-Regular;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 500;
}

@mixin label {
  font-family: Poppins-Regular;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400;
}

@mixin paragraph {
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.6;
  font-weight: 400;
}
@mixin hints {
  font-family: Poppins-italic;
  font-size: 12px;
  line-height: 1.2;
  font-weight: 400;
}

%heading {
  @include heading;
}
%subheading {
  @include subheading;
}
%medium {
  @include medium;
}
%label {
  @include label;
}
%paragraph {
  @include paragraph;
}
%hints {
  @include hints;
}
.round-img {
  width: 50px;  /* Adjust the size as needed */
  height: 50px; /* Adjust the size as needed */
  border-radius: 50%;
  object-fit: cover;
}
.main-container[_ngcontent-ng-c2735839156] input[_ngcontent-ng-c2735839156] {
  max-width: 187px;
}
.container[_ngcontent-ng-c578981192] {
  width: calc(100% - 315px);
  padding-left: 70px;
}
