/* angular-cli file: src/styles.css */
@import "../node_modules/angular-calendar/css/angular-calendar.css";
// Custom Theming for Angular Material
@import "@angular/material/theming";
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.
@import "assets/fonts/line-awesome/css/line-awesome.min.css";
@import "constants.scss";
@import "@fortawesome/fontawesome-free/css/all.css";
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

$custom-typography: mat-typography-config(
  $font-family: "Poppins, sans-serif;",
  $display-4: mat-typography-level(112px, 112px, 300),
  $display-3: mat-typography-level(56px, 56px, 400),
  $display-2: mat-typography-level(45px, 48px, 400),
  $display-1: mat-typography-level(34px, 40px, 400),
  $headline: mat-typography-level(24px, 32px, 400),
  $title: mat-typography-level(20px, 32px, 500),
  $subheading-2: mat-typography-level(16px, 28px, 400),
  $subheading-1: mat-typography-level(15px, 24px, 400),
  $body-2: mat-typography-level(14px, 24px, 500),
  $body-1: mat-typography-level(14px, 20px, 400),
  $caption: mat-typography-level(12px, 20px, 400),
  $button: mat-typography-level(14px, 14px, 500),
  $input: mat-typography-level(inherit, 1.125, 400),
);
@include mat-typography-hierarchy($custom-typography);
@include mat-core();

$primary-color: (
  100: $primary,
  500: $primary,
  700: $primary,
  contrast: (
    100: $light-primary-text,
    500: $light-primary-text,
    700: $light-primary-text,
  ),
);


$accent-color: (
  100: $third,
  500: $third,
  700: $third,
  contrast: (
    100: $light-primary-text,
    500: $light-primary-text,
    700: $light-primary-text,
  ),
);

$warn-color: (
  100: $red,
  500: $red,
  700: $red,
  contrast: (
    100: $light-primary-text,
    500: $light-primary-text,
    700: $light-primary-text,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat-palette($primary-color, 500);
$app-accent: mat-palette($accent-color, 500);
$app-warn: mat-palette($warn-color, 500);

$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

$app-theme: mat-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($app-theme);

// Override dialog border radius
.mat-dialog-container {
  border-radius: 16px !important;
  background-color: $real-white;
}

// style label and input\
// shared input style
%input-default {
  padding-left: 15px;
  border: 0;  // This line removes the border
  background-color: $white;
  color: $black;
  @extend %medium;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  min-width: 2px;
  height: 40px;
  margin-bottom: 8px;

  line-height: 40px;
  &::placeholder {
    color: $grey;
  }
  @media #{$mobile} {
    //min-width: 150px;
  }
}

.label {
  color: $black;
  @extend %label;
  display: block;
  margin: 8px 0px;
}
.mat-radio-button ~ .mat-radio-button {
  margin-left: 8px;
}
input,
textarea {
  @extend %input-default;

  &:read-only {
    color: $black;
    outline: none;
    opacity: 0.6;
  }
  &:disabled {
    color: $black;
    opacity: 0.6;
    cursor: not-allowed;
  }
  &[type="file"] {
    display: none;
  }
}
textarea {
  resize: none;
}
button {
  @extend %heading;
  font-size: 14px;
}

.select {
  @extend %input-default;
  padding: 0 10px;
  .la {
    margin-left: auto;
  }
  // .mat-select-arrow-wrapper {
  //   opacity: 0;
  //   visibility: hidden;
  //   display: none;
  // }
  .mat-select-value {
    max-width: none;
  }
  .mat-select-trigger {
    width: 100%;
  }
}

// // select
// mat-select {
//   @extend %input-default;
//   padding: 0 10px;
//   .la {
//     margin-left: auto;
//   }
//   // .mat-select-arrow-wrapper {
//   //   opacity: 0;
//   //   visibility: hidden;
//   //   display: none;
//   // }
//   .mat-select-value {
//     max-width: none;
//   }
//   .mat-select-trigger {
//     width: 100%;
//   }
// }

// .mat-select-value {
//   color: $black;
//   @extend %medium;
//   border-radius: 8px;
// }

mat-select-trigger {
  display: flex;
  align-items: center;
  color: $black;
}

mat-option {
  width: 100%;
  height: 10%;
  display: block;
  padding: 0 16px;
  color: $black;
}

// display error in red
.error {
  // margin-top: 3px;
  display: block;
  @extend %paragraph;
  color: $red;
}

// date picker
.datepicker {
  display: flex;
  position: relative;
  input {
    @extend %input-default;
  }
  mat-datepicker-toggle {
    position: absolute;
    right: 5px;
  }
}

/** Remove Arrows from input="number" **/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: $real-white;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: $green;
}
// TABLE
.mat-table {
  width: 100%;
  .mat-row:nth-child(odd) {
    background-color: $white;
  }
  th.mat-header-cell {
    border: none;
    @extend %paragraph;
    color: $black;
  }
  .mat-cell {
    @extend %paragraph;
    color: $black;
    border: none;
  }
  .round-img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 4px;
  }
}

.success-snackbar {
  background-color: $green;
  color: $real-white;
}

.failure-snackbar {
  background-color: $red;
  color: $real-white;
}

.warning-snackbar {
  background-color: #FFCC00;
  color: $black;
}

* {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  background-color: $real-white;
}
